import * as React from "react";

export type GenericInputType = "text" | "dropdown" | "multiselect";

export type FieldValue = string | string[];

interface IFormatRegex {
  pattern: RegExp;
  errorMessage: string;
}

export const FormatRegex = {
  English: ((): IFormatRegex => ({
    pattern: /[a-zA-Z]*/,
    errorMessage: "English letters Only"
  }))(),
  Numbers: ((): IFormatRegex => ({
    pattern: /\d*/,
    errorMessage: "Numbers Only"
  }))()
};

export interface GenericField {
  type: GenericInputType;
  name: string;

  disabled?: boolean;
  optional?: boolean;
  multiline?: boolean;
  label?: string;
  errorMessage?: string;
  placeHolder?: string;
  value?: string;
  defaultValue?: string;
  formatRegex?: IFormatRegex;
  suggestions?: string[];

  options?: string[];
}

export interface GenericFieldProps extends GenericField {
  setValue: (v: FieldValue) => void;
}

export class GenericFieldComponent<
  P = {},
  S = {},
  SS = any
> extends React.Component<P & GenericFieldProps, S, SS> {
  render() {
    return (
      <>
        {() => {
          throw new Error("'render'is not Implemented");
        }}
      </>
    );
  }
}
