import * as React from "react";
import { Route, Link as RouterLink, withRouter } from "react-router-dom";

function MyLink({
  to,
  location,
  children,
  staticContext,
  match,
  history,
  ...rest
}: any) {
  return (
    <Route
      render={() => (
        <RouterLink replace={to === location.pathname} to={to} {...rest}>
          {children}
        </RouterLink>
      )}
    />
  );
}

export default withRouter(MyLink);
