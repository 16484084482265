import React from "react";
import GenericForm from "../components/GenericForm";
import { ListState, actionCreators, Animal } from "../stores/animalListStore";
import { connect } from "react-redux";
import { Button, Fab, Zoom, Icon } from "@material-ui/core";

import suggestions from "../data/speciesOptions.json";
import { FormatRegex } from "../components/GenericField";
import { RouteComponentProps } from "react-router";
import * as Common from "../stores/commonStore";
import { ApplicationState } from "../stores";
import { UserState } from "../stores/userStore";

type SpeciesProps = typeof actionCreators &
  ListState &
  RouteComponentProps<any> &
  typeof Common.actionCreators & { user: UserState };

class AnimalPage extends React.Component<SpeciesProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isEdit: true,
      isNew: true
    };
    let { name } = props.match.params;
    if (name) {
      this.props.loadAnimal(name);
      let isNew = false;

      this.state = {
        isNew,
        name
      };
    }
  }

  // let values = props.list.filter(
  //   (a: Animal) => a.scientificName === name
  // )[0];

  componentDidUpdate(prevProps: SpeciesProps): any {
    if (
      prevProps.isLoading === true &&
      this.props.isLoading === false &&
      !this.props.err
    ) {
      this.props.history.push("/home");
    }
  }

  render() {
    let genericFormRef: GenericForm<Animal>;

    let { isNew, isEdit } = this.state;

    let scientificName = "";

    if (!this.state.isNew && this.props.displaySpecies) {
      let { genus, species, subspecies } = this.props.displaySpecies;
      scientificName = `${genus} ${species} ${subspecies || ""}`;
    }
    return (
      <>
        <GenericForm<Animal>
          title={
            this.props.isLoading
              ? " "
              : this.state.isNew
              ? "Add new Species"
              : this.state.isEdit
              ? "Editing " + scientificName
              : "Viewing " + scientificName
          }
          ref={r => (genericFormRef = r)}
          disabled={this.props.isLoading}
          disableFields={!this.state.isEdit}
          errorMessage={this.props.err}
          fields={[
            {
              name: "grouping",
              label: "Grouping",
              type: "dropdown",
              options: suggestions.group
            },
            {
              name: "order",
              label: "Order",
              type: "text",
              formatRegex: FormatRegex.English,
              suggestions: suggestions.order
            },
            {
              name: "family",
              label: "Family",
              type: "text",
              formatRegex: FormatRegex.English,
              suggestions: suggestions.family
            },
            {
              name: "genus",
              label: "Genus",
              type: "text",
              formatRegex: FormatRegex.English,
              suggestions: suggestions.genus
            },
            {
              name: "species",
              label: "Species",
              type: "text",
              formatRegex: FormatRegex.English,
              suggestions: suggestions.species
            },
            {
              name: "subspecies",
              label: "Subspecies",
              type: "text",
              formatRegex: FormatRegex.English,
              optional: true,
              suggestions: suggestions.subspecies
            },
            {
              name: "reference",
              label: "Reference",
              type: "text",
              multiline: true
            },
            {
              name: "variety",
              label: "Variety",
              type: "text",
              formatRegex: FormatRegex.English,
              optional: true,
              suggestions: suggestions.variety
            },
            {
              name: "commonNames",
              label: "Common Names",
              type: "text",
              optional: true
            },
            {
              name: "environment",
              label: "Environment",
              type: "dropdown",
              options: ["Marine", "Terrestrial", "Aquatic"],
              optional: true
            },
            {
              name: "nativity",
              label: "Nativity",
              type: "dropdown",
              options: suggestions["native, invasive, introduced?"],
              optional: true
            },
            {
              name: "habitat",
              label: "Habitat",
              type: "multiselect",
              options: ["Terrestrial", "Marine", "Aquatic"],
              optional: true
            },
            {
              name: "activityTime",
              label: "Activity Time",
              type: "multiselect",
              options: ["Nocturnal", "Crepuscular", "Diurnal"],
              optional: true
            },
            {
              name: "cites",
              label: "CITES",
              type: "multiselect",
              options: suggestions.CITES,
              optional: true
            },
            {
              name: "iucn",
              label: "IUCN",
              type: "dropdown",
              options: suggestions.IUCN_Redlist,
              optional: true
            },
            {
              name: "description",
              label: "Description",
              type: "text",
              optional: true,
              multiline: true
            },
            {
              name: "reproduction",
              label: "Reproduction",
              type: "text",
              optional: true,
              multiline: true
            },
            {
              name: "diet",
              label: "Diet",
              type: "text",
              optional: true,
              multiline: true
            },
            {
              name: "relationshipToHumans",
              label: "Relationship to Humans",
              type: "text",
              optional: true,
              multiline: true
            },
            {
              name: "benefits",
              label: "Benefits",
              type: "text",
              optional: true,
              multiline: true
            }
          ]}
          onSubmit={
            !this.state.isEdit
              ? () => this.setState({ isEdit: true })
              : this.onSubmitHandler
          }
          hideSubmit
          fieldValues={isNew ? undefined : (this.props.displaySpecies as any)}
          submitDisplayText={
            this.state.isNew ? "Add" : this.state.isEdit ? "Save" : "Edit"
          }
          style={{ marginBottom: "100px" }}
        />
        {this.props.isLoading || (
          <>
            <div
              style={{
                position: "fixed",
                right: "40px",
                bottom: "40px"
              }}
            >
              <Zoom
                in={isNew}
                timeout={500}
                style={{
                  transitionDelay: `0ms`
                }}
                unmountOnExit
              >
                <Fab
                  color="primary"
                  disabled={this.props.isLoading}
                  onClick={(e: any) =>
                    genericFormRef.handleSubmitButtonClick(e)
                  }
                  style={{ margin: "5px" }}
                >
                  Add
                </Fab>
              </Zoom>
              <Zoom
                in={!isNew && isEdit}
                timeout={{ enter: 500, exit: 900 }}
                style={{
                  transitionDelay: `0ms`
                }}
                unmountOnExit
              >
                <Fab
                  color="primary"
                  disabled={this.props.isLoading}
                  onClick={(e: any) =>
                    genericFormRef.handleSubmitButtonClick(e)
                  }
                  style={{ margin: "5px", backgroundColor: "#21b6ae" }}
                >
                  <Icon>save</Icon>
                </Fab>
              </Zoom>
              <Zoom
                in={!isNew && isEdit}
                timeout={{ enter: 700, exit: 700 }}
                style={{
                  transitionDelay: `0ms`
                }}
                unmountOnExit
              >
                <Fab
                  onClick={() => this.setState({ isEdit: false })}
                  style={{ margin: "5px" }}
                >
                  <Icon>close</Icon>
                </Fab>
              </Zoom>
              {this.props.user.isAdmin && !this.state.isNew && (
                <Zoom
                  in={isEdit && !isNew && this.props.user.isAdmin}
                  timeout={{ enter: 900, exit: 500 }}
                  style={{
                    transitionDelay: `0ms`
                  }}
                  unmountOnExit
                >
                  <Fab
                    style={{ margin: "5px" }}
                    onClick={() => {
                      this.props.showDialog({
                        title: "",
                        message:
                          "Are you sure you want to delete " +
                          this.state.name +
                          "?",
                        type: "YesNo",
                        onYes: () => {
                          this.props.deleteSpecies(this.state.name);
                        }
                      });
                    }}
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Fab>
                </Zoom>
              )}
              <Zoom
                in={!isNew && !isEdit}
                timeout={{ enter: 1000, exit: 100 }}
                style={{
                  transitionDelay: `0ms`,
                  position: "fixed",
                  right: "40px",
                  bottom: "40px"
                }}
                unmountOnExit
              >
                <Fab
                  color="primary"
                  onClick={() => this.setState({ isEdit: true })}
                  style={{ margin: "5px" }}
                >
                  <Icon>edit</Icon>
                </Fab>
              </Zoom>
            </div>
          </>
        )}

        <Button
          onClick={() => {
            this.props.history.push("/home");
          }}
          variant="outlined"
          color="primary"
          style={{ position: "fixed", left: "30px", bottom: "40px" }}
        >
          Back
        </Button>
      </>
    );
  }
  onSubmitHandler = (n: Animal) => {
    if (this.state.isEdit) {
      this.props.updateSpecies(n);
    } else {
      this.props.addSpecies(n);
    }
  };
}

export default Common.connectCommonStore(
  connect(
    (state: ApplicationState) => ({ ...state.list, user: state.user }),
    actionCreators
  )(AnimalPage as any)
) as any;
