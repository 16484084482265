import React from "react";
import "./App.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ApplicationState, actions } from "./stores";
import {
  LinearProgress,
  AppBar,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import GlobalModel from "./components/GlobalModal";
import GlobalSnackbar from "./components/GlobalSnackbar";
import MyLink from "./components/MyLink";
import * as List from "./stores/animalListStore";
import * as User from "./stores/userStore";
import * as Admin from "./stores/adminStore";
import * as Common from "./stores/commonStore";
import Routes from "./Routes";

type AppProps = {
  store: ApplicationState;
  list: typeof List.actionCreators;
  common: typeof Common.actionCreators;
  user: typeof User.actionCreators;
  admin: typeof Admin.actionCreators;
};

const App: React.FC<AppProps> = props => {
  let { user } = props.store;

  React.useEffect(() => {
    props.user.init();
  }, [props.user]);

  let isLoading = Object.values(props.store).reduce(
    (acc, cur) => acc || cur.isLoading,
    false
  );
  return (
    <>
      {props.store.user.isLoggedIn && (
        <AppBar position="static">
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div>
              <MyLink
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  display: "inline-block"
                }}
              >
                <Typography variant="h6" className="">
                  Hayat Portal
                </Typography>
              </MyLink>

              {props.store.user.isAdmin && (
                <MyLink
                  to="admin"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    display: "inline-block"
                  }}
                >
                  <Typography
                    style={{ display: "inline-block", marginLeft: "20px" }}
                  >
                    User Control
                  </Typography>
                </MyLink>
              )}
            </div>
            <div>
              <Typography
                variant="h6"
                className=""
                style={{ display: "inline-block", marginRight: "20px" }}
              >
                {user.isRegistered ? user.displayName : ""}
              </Typography>
              <Button
                variant="text"
                style={{ display: "inline-block" }}
                onClick={e => {
                  e.preventDefault();
                  props.user.logout();
                }}
              >
                Logout
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      )}
      <LinearProgress hidden={!isLoading} color="primary" />
      <GlobalModel />
      <GlobalSnackbar />
      <Routes />
    </>
  );
};
let actionNames = Object.keys(actions);
let actionValuePair = Object.values(actions).map((v, i) => ({
  key: actionNames[i],
  value: v
}));

let dispatcher: any = (dispatch: any) =>
  actionValuePair.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.key]: Object.values(cur.value).reduce(
        (acc2, cur2) => ({
          ...acc2,
          [cur2.name]: (a: any, b: any) => dispatch(cur2(a, b)) as any
        }),
        {}
      )
    }),
    {}
  );

export default withRouter(connect(
  (state: ApplicationState) => ({ store: { ...state } }),
  dispatch => dispatcher(dispatch)
)(App as any) as any);
