import React from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import { GenericFieldComponent } from ".";

class MuliSelect extends GenericFieldComponent<{ options: string[] }, {}> {
  render() {
    let {
      label,
      name,
      errorMessage,
      optional,
      options,
      value,
      disabled
    } = this.props;

    return (
      <>
        <InputLabel error={!!errorMessage}>
          {(label || name) + (optional ? "" : "*")}
        </InputLabel>
        <Select
          multiple
          value={value || []}
          error={!!errorMessage}
          disabled={disabled}
          required={!optional}
          onChange={event => {
            this.props.setValue(event.target.value as string);
          }}
        >
          {options.map((o, i) => (
            <MenuItem key={i} value={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
}

export default MuliSelect;
