import { AppThunkAction, ApplicationState } from ".";
import { connect } from "react-redux";
import firestore from "../config/firebase";

export interface AdminState {
  isLoading: boolean;
  users: UserInfo[];
}

interface UserInfo {
  displayName: string;
  phoneNumber: string;
}

//Actions
interface REQUEST_USERS_INFO {
  type: "REQUEST_USERS_INFO";
}
interface STOP_LOADING {
  type: "STOP_LOADING";
}
interface SET_USERS {
  type: "SET_USERS";
  users: UserInfo[];
}

type KnownAction = SET_USERS | REQUEST_USERS_INFO | STOP_LOADING;

export const actionCreators = {
  loadRegisteredUsersInfo: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "REQUEST_USERS_INFO" });

    let queerySnap = await firestore.collection("registered_users").get();

    let users = queerySnap.docs.map(
      q => ({ ...q.data(), phoneNumber: q.id.substr(3) } as UserInfo)
    );

    dispatch({ type: "SET_USERS", users });
  },
  registerUser: (phoneNumber: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    //check if user exist
    let querrySnap = await firestore
      .collection("registered_users")
      .doc("965" + phoneNumber)
      .get();

    if (!querrySnap.exists) {
      await firestore
        .collection("registered_users")
        .doc("965" + phoneNumber)
        .set({ displayName: "" });

      dispatch({
        type: "SET_USERS",
        users: [...getState().admin.users, { displayName: "", phoneNumber }]
      });
    } else {
      dispatch({ type: "STOP_LOADING" });
    }
  },
  unregisterUser: (phoneNumber: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "REQUEST_USERS_INFO" });

    let querrySnap = await firestore
      .collection("registered_users")
      .doc("965" + phoneNumber)
      .get();

    if (querrySnap.exists) {
      await firestore
        .collection("registered_users")
        .doc("965" + phoneNumber)
        .delete();

      dispatch({
        type: "SET_USERS",
        users: getState().admin.users.filter(v => v.phoneNumber !== phoneNumber)
      });
    } else {
      dispatch({ type: "STOP_LOADING" });
    }
  }
};

const unloadedState: AdminState = {
  users: [],
  isLoading: false
};

export function reducer(state: AdminState, action: KnownAction): AdminState {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        ...action,
        isLoading: false
      };
    case "REQUEST_USERS_INFO":
      return {
        ...state,
        isLoading: true
      };
    case "STOP_LOADING":
      return {
        ...state,
        isLoading: false
      };
  }
  return state || unloadedState;
}

export const connectCommonStore = (component: any) => {
  return connect(
    (state: ApplicationState) => state.common,
    actionCreators
  )(component);
};
