import { AppThunkAction, ApplicationState } from ".";
import { connect } from "react-redux";

export interface CommonState {
  shouldDisplayDialog: boolean;
  dialog: DialogProps;

  snackbarDisplay: SnackbarProps;
}

interface DialogProps {
  type: "YesNo" | "Input";
  title: string;
  message: string;

  onYes: () => void;
  onNo?: () => void;
  onCancel?: () => void;
  onSubmit?: (value: string) => void;
}

interface SnackbarProps {
  message: string;
  err?: boolean;
}

//Actions
interface SHOW_DIALOG {
  type: "SHOW_DIALOG";
  dialog: DialogProps;
}
interface RESOLVE_DIALOG {
  type: "RESOLVE_DIALOG";
}
interface SHOW_SNACKBAR {
  type: "SHOW_SNACKBAR";
  snackbarProps: SnackbarProps;
}
interface DISMISS_SNACKBAR {
  type: "DISMISS_SNACKBAR";
}

export type CommonActions =
  | SHOW_DIALOG
  | RESOLVE_DIALOG
  | SHOW_SNACKBAR
  | DISMISS_SNACKBAR;

export const actionCreators = {
  showDialog: (dialog: DialogProps): AppThunkAction<CommonActions> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "SHOW_DIALOG", dialog });
  },
  resolveDialog: (): AppThunkAction<CommonActions> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "RESOLVE_DIALOG" });
  },
  showSnackbar: (
    snackbarProps: SnackbarProps
  ): AppThunkAction<CommonActions> => async (dispatch, getState) => {
    dispatch({ type: "SHOW_SNACKBAR", snackbarProps });
  },
  dismissSnackbar: (): AppThunkAction<CommonActions> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "DISMISS_SNACKBAR" });
  }
};

const defaultSnackbarProps: SnackbarProps = { message: null, err: false };

const unloadedState: CommonState = {
  shouldDisplayDialog: false,
  dialog: { title: "", message: "", onYes: () => {}, type: "YesNo" },

  snackbarDisplay: defaultSnackbarProps
};

export function reducer(
  state: CommonState,
  action: CommonActions
): CommonState {
  switch (action.type) {
    case "SHOW_DIALOG":
      return {
        ...state,
        ...action,
        shouldDisplayDialog: true
      };
    case "RESOLVE_DIALOG":
      return {
        ...state,
        shouldDisplayDialog: false
      };
    case "SHOW_SNACKBAR":
      return {
        ...state,
        snackbarDisplay: action.snackbarProps
      };
    case "DISMISS_SNACKBAR":
      return {
        ...state,
        snackbarDisplay: { ...state.snackbarDisplay, message: null }
      };
  }
  return state || unloadedState;
}

export const connectCommonStore = (component: any) => {
  return connect(
    (state: ApplicationState) => state.common,
    actionCreators
  )(component);
};

export type CommonStoreProps = CommonState & typeof actionCreators;
