import { AppThunkAction } from ".";
import * as firebase from "firebase/app";
import "../config/firebase";
import firestore from "../config/firebase";
import { CommonActions } from "./commonStore";

export interface UserState {
  isLoading: boolean;

  isLoggedIn: boolean;

  phoneNumber?: string;
  displayName?: string;
  userType: UserType;
  isAdmin: boolean;
  isRegistered: boolean;
  confirmationResult?: firebase.auth.ConfirmationResult;
}

enum UserType {
  NONE,
  ADMIN,
  REGISTERED,
  UNREGISTERED
}

//Actions
interface REQUEST_LOGIN {
  type: "REQUEST_LOGIN";
  phoneNumber: string;
}

interface RECEIVE_LOGIN {
  type: "RECEIVE_LOGIN";
  confirmationResult: firebase.auth.ConfirmationResult;
}

interface REQUEST_VERIFY_CODE {
  type: "REQUEST_VERIFY_CODE";
}

interface RECEIVE_VERIFY_CODE {
  type: "RECEIVE_VERIFY_CODE";
  displayName: string;
}

interface REQUEST_LOCAL_USER {
  type: "REQUEST_LOCAL_USER";
}
interface RECEIVE_LOCAL_USER {
  type: "RECEIVE_LOCAL_USER";
  user: firebase.User | null;
  userType: UserType;
}

interface REQUEST_LOGOUT {
  type: "REQUEST_LOGOUT";
}

interface START_LOADING {
  type: "START_LOADING";
  reason?: string;
}

interface SET_DISPLAYNAME {
  type: "SET_DISPLAYNAME";
  displayName: string;
}

type KnownAction =
  | CommonActions
  | REQUEST_LOGIN
  | RECEIVE_LOGIN
  | REQUEST_VERIFY_CODE
  | RECEIVE_VERIFY_CODE
  | REQUEST_LOCAL_USER
  | RECEIVE_LOCAL_USER
  | REQUEST_LOGOUT
  | START_LOADING
  | SET_DISPLAYNAME;

export const actionCreators = {
  init: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "REQUEST_LOCAL_USER" });

    firebase.auth().onAuthStateChanged(async function(user) {
      if (user) {
        let userType = await GetUserType(user.phoneNumber);
        dispatch({
          type: "RECEIVE_LOCAL_USER",
          user: user,
          userType
        });
      } else {
        dispatch({
          type: "RECEIVE_LOCAL_USER",
          user: null,
          userType: null
        });
      }
    });
  },
  sendSMS: (
    phoneNumber: string,
    appVerifier: firebase.auth.ApplicationVerifier
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({
      type: "REQUEST_LOGIN",
      phoneNumber
    });

    try {
      let confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber("+965" + phoneNumber, appVerifier);

      dispatch({
        type: "RECEIVE_LOGIN",
        confirmationResult
      });
      dispatch({
        type: "SHOW_SNACKBAR",
        snackbarProps: { message: "SMS Sent!" }
      });
    } catch (e) {
      dispatch({
        type: "RECEIVE_LOGIN",
        confirmationResult: null
      });
      dispatch({
        type: "SHOW_SNACKBAR",
        snackbarProps: { message: e.message, err: true }
      });
    }
  },
  verifyCode: (code: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "REQUEST_VERIFY_CODE" });
    try {
      let { user } = await getState().user.confirmationResult.confirm(code);

      let userType = await GetUserType(user.phoneNumber);

      dispatch({
        type: "RECEIVE_LOCAL_USER",
        user,
        userType
      });
    } catch (e) {
      dispatch({
        type: "RECEIVE_VERIFY_CODE",
        displayName: ""
      });
      dispatch({
        type: "SHOW_SNACKBAR",
        snackbarProps: { message: e.message, err: true }
      });
    }
  },

  updateDisplayName: (
    displayName: string,
    callack: () => void = () => {}
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    if (!firebase.auth().currentUser) {
      dispatch({
        type: "SHOW_SNACKBAR",
        snackbarProps: { message: "LOGIN FIRST", err: true }
      });
      return;
    }
    dispatch({ type: "START_LOADING", reason: "Updating display name" });

    await firebase.auth().currentUser.updateProfile({ displayName });

    if (getState().user.userType === UserType.REGISTERED) {
      await firestore
        .collection("registered_users")
        .doc(getState().user.phoneNumber.substr(1))
        .update("displayName", displayName);
    }

    dispatch({
      type: "SET_DISPLAYNAME",
      displayName: firebase.auth().currentUser.displayName
    });
    callack();
  },

  logout: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    await firebase.auth().signOut();
    dispatch({ type: "REQUEST_LOGOUT" });
  }
};

const unloadedState: UserState =
  process.env.REACT_APP_SHOULD_FAKE_LOGIN === "true"
    ? {
        isLoading: false,
        isLoggedIn: true,
        userType: UserType.REGISTERED,
        isAdmin: false,
        phoneNumber: "12345678",
        isRegistered: true
      }
    : {
        isLoading: false,
        isLoggedIn: false,
        isAdmin: false,
        userType: UserType.REGISTERED,
        isRegistered: false
      };

export function reducer(state: UserState, action: KnownAction): UserState {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        ...action,
        isLoading: true
      };
    case "RECEIVE_LOGIN":
      return {
        ...state,
        ...action,
        isLoading: false
      };
    case "REQUEST_VERIFY_CODE":
      return {
        ...state,
        isLoading: true
      };
    case "RECEIVE_VERIFY_CODE":
      return {
        ...state,
        ...action,
        isLoggedIn: !!action.displayName,
        isLoading: false
      };
    case "REQUEST_LOCAL_USER":
      return {
        ...state,
        isLoading: true
      };
    case "RECEIVE_LOCAL_USER":
      return action.user
        ? {
            confirmationResult: null,

            isLoggedIn: true,
            phoneNumber: action.user.phoneNumber,
            displayName: action.user.displayName,
            userType: action.userType,
            isAdmin: action.userType === UserType.ADMIN,
            isRegistered: action.userType !== UserType.UNREGISTERED,
            isLoading: false
          }
        : { ...state, isLoading: false };
    case "REQUEST_LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        confirmationResult: null,
        phoneNumber: null
      };
    case "START_LOADING":
      return {
        ...state,
        isLoading: true
      };
    case "SET_DISPLAYNAME":
      return {
        ...state,
        ...action,
        isLoading: false
      };
  }
  return state || unloadedState;
}

const GetUserType = async (phoneNumber: string): Promise<UserType> => {
  let adminSanp = await firestore
    .collection("admin_users")
    .doc(phoneNumber.slice(1))
    .get();

  if (adminSanp.exists) {
    return UserType.ADMIN;
  }

  let usersSnap = await firestore
    .collection("registered_users")
    .doc(phoneNumber.slice(1))
    .get();

  if (usersSnap.exists) {
    return UserType.REGISTERED;
  }

  return UserType.UNREGISTERED;
};
