import React from "react";
import * as _ from "lodash";
import ErrorIcon from "@material-ui/icons/Error";
import {
  GenericFieldComponent,
  GenericField,
  GenericInputType,
  FieldValue
} from "./GenericField";
import TextField from "./GenericField/TextField";
import Dropdown from "./GenericField/Dropdown";
import MuliSelect from "./GenericField/MultiSelect";
import {
  FormControl,
  FormHelperText,
  Snackbar,
  SnackbarContent,
  IconButton,
  Button,
  Container
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";

const genericFieldsMapper: {
  [genericType in GenericInputType]: GenericFieldComponent;
} = {
  text: TextField as any, //because duck you typescript you son of a gun
  dropdown: Dropdown as any, //because duck you typescript you son of a gun
  multiselect: MuliSelect as any //because duck you typescript you son of a gun
};

export interface FormState {
  errorTitle?: string;

  values: { [key: string]: FieldValue };
  errors: { [key: string]: FieldValue };
  warnings: { [key: string]: FieldValue }; //TODO use this
  display: { [key: string]: FieldValue };
}

export interface FormProps<T> {
  fields: GenericField[];

  onSubmit: (form: T) => void;

  disableSubmit?: boolean;
  disabled?: boolean;
  disableFields?: boolean;
  hideSubmit?: boolean;
  title?: string;
  submitDisplayText?: string;
  errorMessage?: string;

  fieldValues?: { [key: string]: FieldValue };

  style?: React.CSSProperties;
}

class GenericForm<T = any> extends React.Component<FormProps<T>, FormState> {
  fieldDictionary: { [name: string]: GenericField } = {};

  constructor(props: FormProps<T>) {
    super(props);

    this.state = {
      title: "Form",
      submitDisplay: "Submit",
      errorTitle: undefined,
      values: props.fields
        .map(f => ({ [f.name]: null }))
        .reduce((cumilative, pair) => {
          for (let key in pair) cumilative[key] = pair[key];
          return cumilative;
        }),
      errors: {},
      warnings: {},
      display: {}
    } as FormState;

    if (props.fieldValues) {
      this.state = {
        ...this.state,
        values: {
          ...this.state.values,
          ..._.pick(props.fieldValues, [...props.fields.map(f => f.name)])
        }
      };
    }

    this.fieldDictionary = props.fields.reduce(
      (cumilative, field) => {
        cumilative[field.name] = field;
        return cumilative as any;
      },
      {} as { [name: string]: GenericField }
    );
  }

  updateFormAttribute(newState: Partial<FormState>) {
    this.setState({ ...newState } as FormState);
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    this.fieldDictionary = this.props.fields.reduce(
      (cumilative, field) => {
        cumilative[field.name] = field;
        return cumilative as any;
      },
      {} as { [name: string]: GenericField }
    );
    if (prevProps.fieldValues !== this.props.fieldValues) {
      this.setState({ values: this.props!.fieldValues! });
    } else if (
      this.props.fieldValues === null &&
      prevProps.fieldValues !== null
    ) {
      this.setState({
        values: this.props.fields
          .map(f => ({ [f.name]: "" }))
          .reduce((cumilative, pair) => {
            for (let key in pair) cumilative[key] = pair[key];
            return cumilative;
          })
      });
    }
  }

  handleSubmitButtonClick = (e: any) => {
    e.preventDefault();
    this.onFormFieldsSubmit();
  };

  onFormFieldsSubmit = () => {
    let values = this.state.values;
    let errors: { [key: string]: string } = {};
    let { display } = this.state;

    let arr = this.props.fields.map(f => f.name);

    for (let i in arr) {
      let key = arr[i];
      let field = this.fieldDictionary[key];
      if (field.optional) continue;

      let value = values[key];

      //check exsistance of value
      if (!value || (Array.isArray(value) && value.length === 0)) {
        //because [] !== []. Thanks Javascript
        errors[key] = field.errorMessage
          ? field.errorMessage
          : `Missing ${display[key] ? display[key] : key}`;
      }

      //check format
      let format = field.formatRegex;
      if (
        format &&
        typeof value === "string" &&
        !value.match(new RegExp(`^${format.pattern.source}$`))
      ) {
        errors[key] = format.errorMessage || "Invalid format";
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
        errorTitle: "Please fix errors"
      } as FormState);
      return;
    } else {
      this.setState({ errors: {}, errorTitle: "" } as FormState);
    }

    this.props.onSubmit((this.state.values as any) as T);
  };

  dismissFormError = () => {
    this.updateFormAttribute({ errorTitle: undefined });
  };

  setFieldValue = (fieldName: string, value: FieldValue) => {
    let format = this.fieldDictionary[fieldName].formatRegex;
    if (
      format &&
      typeof value === "string" &&
      !(value as string).match(new RegExp(`^${format.pattern.source}$`))
    ) {
      this.setState({
        errors: { ...this.state.errors, [fieldName]: undefined },
        warnings: { ...this.state.warnings, [fieldName]: format.errorMessage }
      });
    } else {
      this.setState({
        values: { ...this.state.values, [fieldName]: value },
        errors: { ...this.state.errors, [fieldName]: undefined },
        warnings: { ...this.state.warnings, [fieldName]: undefined }
      });
    }
  };

  render = () => {
    let { errorTitle, errors, warnings } = this.state;
    return (
      <Container style={this.props.style}>
        <div>
          <h1>{this.props.title || "Form"}</h1>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={!!errorTitle}
            autoHideDuration={6000}
            onClose={this.dismissFormError}
            onClick={this.dismissFormError}
          >
            <SnackbarContent
              style={{ backgroundColor: red[600] }}
              aria-describedby="client-snackbar"
              message={
                <span
                  id="client-snackbar"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <ErrorIcon
                    style={{
                      opacity: 0.9,
                      backgroundColor: red[600],
                      marginRight: 10
                    }}
                  />
                  {errorTitle}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.dismissFormError}
                >
                  <CloseIcon style={{ fontSize: 20 }} />
                </IconButton>
              ]}
            />
          </Snackbar>

          {/* {this.props.errorMessage && <SnackbarContent message />} */}

          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={this.handleSubmitButtonClick}
          >
            {this.props.fields.map((f, i) => (
              <FormControl margin="normal" key={i}>
                {this.renderGenericField(f, (v: FieldValue) => {
                  this.setFieldValue(f.name, v);
                })}
                {errors[f.name] && (
                  <FormHelperText error={!!errors[f.name]}>
                    {errors[f.name]}
                  </FormHelperText>
                )}
                {warnings[f.name] && (
                  <FormHelperText error>{warnings[f.name]}</FormHelperText>
                )}
              </FormControl>
            ))}
            {(this.props.hideSubmit === null || !this.props.hideSubmit) && (
              <>
                <br />
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmitButtonClick}
                    disabled={this.props.disableSubmit || this.props.disabled}
                  >
                    {this.props.submitDisplayText || "Submit"}
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </Container>
    );
  };

  renderGenericField = (
    { type, ...fieldProps }: GenericField,
    setValue: (v: FieldValue) => void
  ) => {
    let genericComponent = genericFieldsMapper[type];
    return React.createElement(genericComponent as any, {
      ...fieldProps,
      value: this.state.values[fieldProps.name],
      label: fieldProps.label || fieldProps.name,
      disabled:
        this.props.disabled || fieldProps.disabled || this.props.disableFields,
      required: !fieldProps.optional,
      errorMessage: this.state.errors[fieldProps.name],
      setValue
    });
  };
}

export default GenericForm;
