import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  TextField
} from "@material-ui/core";

import * as Common from "../stores/commonStore";

type ModalProps = typeof Common.actionCreators & Common.CommonState;

const GlobalModel: React.FC<ModalProps> = (props: ModalProps) => {
  let [textValue, setTextValue] = React.useState("");

  let { shouldDisplayDialog, dialog, resolveDialog } = props;

  return (
    <Dialog
      open={shouldDisplayDialog}
      onClose={() => {
        resolveDialog();
        dialog.onCancel && dialog.onCancel();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialog.message}
        </DialogContentText>
        {dialog.type === "Input" && (
          <TextField
            value={textValue}
            onChange={v => setTextValue(v.currentTarget.value)}
            placeholder="Enter something"
          />
        )}
      </DialogContent>
      <DialogActions>
        {dialog.type === "YesNo" && (
          <>
            <Button
              onClick={() => {
                resolveDialog();
                dialog.onNo && dialog.onNo();
              }}
              color="default"
            >
              No
            </Button>
            <Button
              onClick={() => {
                resolveDialog();
                dialog.onYes();
              }}
              color="secondary"
              autoFocus
            >
              Yes
            </Button>
          </>
        )}

        {dialog.type === "Input" && (
          <>
            <Button
              onClick={() => {
                resolveDialog();
                dialog.onSubmit && dialog.onSubmit(textValue);
              }}
              color="primary"
            >
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Common.connectCommonStore(GlobalModel);
