import * as React from "react";
import MyLink from "../components/MyLink";
import {
  Container,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Fab,
  Zoom
} from "@material-ui/core";
import { ListState, actionCreators } from "../stores/animalListStore";
import AddIcon from "@material-ui/icons/Add";

import { connect } from "react-redux";

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.default
      }
    }
  })
)(TableRow);

type SpeciesProps = typeof actionCreators & ListState;

const HomePage: React.FC<SpeciesProps> = props => {
  React.useEffect(() => {
    props.loadList();
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={{ marginTop: "15px", marginBottom: "100px" }}>
      <MyLink to="/animal">
        <Zoom
          in={true}
          timeout={500}
          style={{
            transitionDelay: `0ms`,
            position: "fixed",
            right: "40px",
            bottom: "40px"
          }}
          unmountOnExit
        >
          <Fab aria-label="add" color="primary">
            <AddIcon />
          </Fab>
        </Zoom>
      </MyLink>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography>Scientific Name</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Last Edited</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.list.map(l => (
            <StyledTableRow key={l.scientificName}>
              <TableCell align="left">
                <MyLink
                  to={"/animal/" + l.scientificName}
                  style={{ fontStyle: "italic" }}
                >
                  <Typography>{l.scientificName.replace(/_/g, " ")}</Typography>
                </MyLink>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  {l.lastEdited.toDate().toLocaleString()}
                </Typography>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default connect(
  (state: ListState) => state.list,
  actionCreators
)(HomePage as any);
