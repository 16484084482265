import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../stores";
import { actionCreators, UserState } from "../stores/userStore";
import { Route, Redirect } from "react-router";

type ProtectedRouteProps = UserState &
  typeof actionCreators & {
    history: History;
    path: string;
    component: React.Component;
    location: Location;
    render: any;

    admin: boolean;
  };

class ProtectedRoute extends React.Component<ProtectedRouteProps, {}> {
  render = () => (
    <Route
      path={this.props.path}
      render={(props: any) => {
        if (!this.props.isLoggedIn) {
          return (
            <Redirect to={"/login?from=" + this.props.location.pathname} />
          );
        }

        if (!this.props.isRegistered) {
          this.props.logout();
          return <Redirect to={"/get-kicked"} />;
        }

        if (!this.props.displayName) {
          return (
            <Redirect to={"/set-name?from=" + this.props.location.pathname} />
          );
        }

        if (this.props.admin && !this.props.isAdmin) {
          return <div>Access Denied</div>;
        }

        let { component } = this.props;

        return (
          React.createElement(component as any, props) || this.props.render()
        );
      }}
    />
  );
}

export default connect(
  (state: ApplicationState) => state.user,
  actionCreators
)(ProtectedRoute as any) as any;
