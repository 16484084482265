import React from "react";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";

import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { connectCommonStore, CommonStoreProps } from "../stores/commonStore";

const GlobalSnackbar: React.FC<CommonStoreProps> = ({
  snackbarDisplay: { message, err },
  dismissSnackbar
}: CommonStoreProps) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={!!message}
      autoHideDuration={15000}
      onClose={dismissSnackbar}
      onClick={dismissSnackbar}
    >
      <SnackbarContent
        style={{ backgroundColor: err ? red[600] : green[600] }}
        aria-describedby="client-snackbar"
        message={
          <span
            id="client-snackbar"
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            {err && (
              <ErrorIcon
                style={{
                  opacity: 0.9,
                  backgroundColor: red[600],
                  marginRight: 10
                }}
              />
            )}
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={dismissSnackbar}
          >
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default connectCommonStore(GlobalSnackbar);
