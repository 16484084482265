import * as React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AnimalPage from "./pages/AnimalPage";
import LoginPage from "./pages/LoginPage";

import ProtectedRoute from "./components/ProtectedRoute";

import SetNamePage from "./pages/SetNamePage";
import UnregisteredUserPage from "./pages/UnregisteredUserPage";
import AdminPage from "./pages/AdminPage";
import HomePage from "./pages/HomePage";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/set-name" component={SetNamePage} />
      <Route path="/get-kicked" component={UnregisteredUserPage} />

      <ProtectedRoute path="/home" component={HomePage} />
      <ProtectedRoute admin path="/admin" component={AdminPage} />
      <ProtectedRoute path="/animal/:name?" component={AnimalPage} />
      <Route exact path="/" render={() => <Redirect to="/home" />} />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default withRouter(Routes);
