import { AppThunkAction } from ".";
import firestore from "../config/firebase";
import * as firebase from "firebase/app";
import { CommonActions } from "./commonStore";

export interface ListState {
  list: SpeciesDisplayData[];
  isLoading: boolean;
  err?: string;
  displaySpecies?: Animal;
}

export interface SpeciesDisplayData {
  lastEdited?: firebase.firestore.Timestamp;
  scientificName: string;
}

export interface User {
  displayName: string;
  phoneNumber: string;
}
export interface Animal {
  genus: string;
  species: string;
  subspecies?: string;
  grouping: string[];
  order: string;
  family: string;
  variety?: string;
  commonNames: string;
  environment?: string[];
  nativity: string[];
  habitat?: string[];
  activityTime: string[];
  reference: string;
  cites?: string[];
  iucn?: string[];
  description?: string;
  reproduction: string;
  diet: string;
  relationshipToHumans: string;
  benefits: string;

  scientificName: string;
}

interface AnimalDoc extends Animal {
  user: User;
  lastEdited: firebase.firestore.Timestamp;
}
//Actions

interface SET_LIST {
  type: "SET_LIST";
  list: SpeciesDisplayData[];
}

interface LOAD_VALUES {
  type: "LOAD_VALUES";
  displaySpecies: Animal;
}

interface REQUEST_LOADING {
  type: "REQUEST_LOADING";
}
interface RECIEVE_LOADING {
  type: "RECIEVE_LOADING";
  err?: string;
}

type KnownAction =
  | CommonActions
  | SET_LIST
  | LOAD_VALUES
  | REQUEST_LOADING
  | RECIEVE_LOADING;

export const actionCreators = {
  loadAnimal: (scientificName: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    let speciesRef = await firestore.collection("species");
    let speciesQuery = speciesRef.where("scientificName", "==", scientificName);
    let x = await speciesQuery.get();
    let displaySpecies = x.docs[0].data() as Animal;

    dispatch({ type: "LOAD_VALUES", displaySpecies });
  },

  loadList: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    let queries = await firestore
      .collection("page")
      .doc("homeTable")
      .get();

    let list: SpeciesDisplayData[] = Object.values(queries.data());
    list = list.map(l => ({
      ...l,
      scientificName: toPascal(l.scientificName)
    }));

    dispatch({ type: "SET_LIST", list });
  },

  /** TODO seperate this into add/update */

  addSpecies: (a: Animal): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    try {
      let animal: AnimalDoc = {
        ...a,
        lastEdited: firebase.firestore.Timestamp.now(),
        user: {
          displayName: firebase.auth().currentUser.displayName,
          phoneNumber: firebase.auth().currentUser.phoneNumber
        }
      };
      animal.scientificName =
        animal.genus +
        "_" +
        animal.species +
        (animal.subspecies ? "_" + animal.subspecies : "");
      animal.scientificName = toPascal(animal.scientificName);

      let animalRef = await firestore.collection("species");
      let animalQuery = animalRef.where(
        "scientificName",
        "==",
        animal.scientificName
      );
      let x = await animalQuery.get();
      //check if add or update
      if (x.size === 0) {
        dispatch({ type: "REQUEST_LOADING" });
        await firestore
          .collection("species")
          .doc()
          .set(animal);

        dispatch({
          type: "SET_LIST",
          list: [...getState().list.list, animal as SpeciesDisplayData]
        });
      } else {
        dispatch({
          type: "RECIEVE_LOADING",
          err: "duplicate species name. contact admin"
        });
      }
    } catch (e) {
      dispatch({ type: "RECIEVE_LOADING", err: e.message });
      dispatch({
        type: "SHOW_SNACKBAR",
        snackbarProps: { message: e.message, err: true }
      });
    }
  },

  updateSpecies: (a: Animal): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    let animal: AnimalDoc = {
      ...a,
      lastEdited: firebase.firestore.Timestamp.now(),
      user: {
        displayName: firebase.auth().currentUser.displayName,
        phoneNumber: firebase.auth().currentUser.phoneNumber
      }
    };
    animal.scientificName =
      animal.genus +
      "_" +
      animal.species +
      (animal.subspecies ? "_" + animal.subspecies : "");
    animal.scientificName = toPascal(animal.scientificName);

    let animalRef = await firestore.collection("species");
    let animalQuery = animalRef.where(
      "scientificName",
      "==",
      getState().list.displaySpecies.scientificName
    );
    let x = await animalQuery.get();

    if (x.size === 1) {
      dispatch({ type: "REQUEST_LOADING" });
      await firestore
        .collection("species")
        .doc(x.docs[0].id) //fixhere
        .set(animal);

      let list = getState().list.list;
      list = list.filter(
        a => a.scientificName !== getState().list.displaySpecies.scientificName
      );
      list = [...list, animal];
      dispatch({ type: "SET_LIST", list });
    } else {
      dispatch({ type: "RECIEVE_LOADING", err: "animal not loaded" });
    }
  },

  deleteSpecies: (
    scientificName: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "REQUEST_LOADING" });
    let user = {
      name: firebase.auth().currentUser.displayName,
      number: firebase.auth().currentUser.phoneNumber
    };

    let deleteSpeciesRef = await firestore.collection("species");
    let speciesSnapshot = await deleteSpeciesRef
      .where("scientificName", "==", scientificName)
      .get();

    let speciesDoc = speciesSnapshot.docs[0];

    if (speciesDoc) {
      await firestore
        .collection("species")
        .doc(speciesDoc.id)
        .set(user, { merge: true });
      await firestore
        .collection("species")
        .doc(speciesDoc.id)
        .delete();
    } else {
      dispatch({ type: "RECIEVE_LOADING", err: "animal not found" });
    }

    let list = getState().list.list;

    list = list.filter(a => a.scientificName !== scientificName);

    dispatch({ type: "SET_LIST", list });
  }
};

//reducer

const unloadedState: ListState = {
  list: [],
  isLoading: false
};
export function reducer(state: ListState, action: KnownAction): ListState {
  switch (action.type) {
    case "SET_LIST":
      return {
        ...state,
        list: action.list.sort((a, b) =>
          a.scientificName.toLowerCase() > b.scientificName.toLowerCase()
            ? 1
            : b.scientificName.toLowerCase() > a.scientificName.toLowerCase()
            ? -1
            : 0
        ),
        isLoading: false
      };

    case "REQUEST_LOADING":
      return {
        ...state,
        isLoading: true
      };
    case "RECIEVE_LOADING":
      return {
        ...state,
        isLoading: false,
        err: action.err
      };
    case "LOAD_VALUES":
      return {
        ...state,
        displaySpecies: action.displaySpecies,
        isLoading: false
      };
  }
  return state || unloadedState;
}

const toPascal = (s: string) => s[0].toUpperCase() + s.toLowerCase().substr(1);
