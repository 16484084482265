import * as React from "react";
import GenericForm from "../components/GenericForm";
import firebase from "firebase/app";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import queryString from "query-string";
import { UserState, actionCreators } from "../stores/userStore";
import { connect } from "react-redux";
import { ApplicationState } from "../stores";

interface LoginParams {
  from: string;
}

type LoginPageProps = UserState &
  typeof actionCreators &
  RouteComponentProps<LoginParams>;

const LoginPage: React.FC<any> = ({
  location,
  sendSMS,
  isLoading,
  isLoggedIn,
  verifyCode,
  phoneNumber,
  confirmationResult
}: LoginPageProps) => {
  React.useEffect(() => {
    if (!(window as any).recaptchaVerifier) {
      (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );
    }

    return () => {
      (window as any).recaptchaVerifier = null;
    };
  }, [location]);

  if (isLoggedIn) {
    if (location.search) {
      const { from } = queryString.parse(location.search);
      return <Redirect to={from as string} />;
    } else {
      return <Redirect to="/home" />;
    }
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div id="recaptcha-container" />
      <GenericForm<any>
        title="Login"
        fields={[
          {
            type: "text",
            name: "phoneNumber",
            label: "Phone Number",
            placeHolder: "12345678",
            formatRegex: {
              pattern: /\d{0,8}/,
              errorMessage: "Phone number must consist of 8 digits!"
            }
          }
        ]}
        fieldValues={{ phoneNumber }}
        disabled={isLoading}
        submitDisplayText={confirmationResult ? "Send again?" : "Send"}
        onSubmit={({ phoneNumber }) => {
          const appVerifier = (window as any).recaptchaVerifier;
          sendSMS(phoneNumber, appVerifier);
        }}
      />

      {confirmationResult && (
        <GenericForm<any>
          title=" "
          fields={[
            {
              type: "text",
              name: "code",
              label: "Verification Code",
              placeHolder: "123456",
              formatRegex: {
                pattern: /\d{0,6}/,
                errorMessage: "Code consists of 6 digits!"
              }
            }
          ]}
          fieldValues={{ code: "" }}
          disabled={isLoading}
          submitDisplayText="Verify"
          onSubmit={({ code }) => {
            verifyCode(code);
          }}
        />
      )}
    </div>
  );
};

export default withRouter(connect(
  (state: ApplicationState) => state.user,
  actionCreators
)(LoginPage as any) as any);
