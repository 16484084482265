import * as React from "react";
import GenericForm from "../components/GenericForm";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import queryString from "query-string";
import { UserState, actionCreators } from "../stores/userStore";
import { connect } from "react-redux";
import { ApplicationState } from "../stores";
import { FormatRegex } from "../components/GenericField";

interface SetNamePageParams {
  from: string;
}

type SetNamePageProps = UserState &
  typeof actionCreators &
  RouteComponentProps<SetNamePageParams>;

const SetNamePage: React.FC<any> = ({
  location,
  updateDisplayName,
  isLoading,
  isLoggedIn,
  phoneNumber,
  displayName
}: SetNamePageProps) => {
  let [hasSubmitted, setHasSubmitted] = React.useState(false);

  if (!isLoggedIn) {
    return <Redirect to="/login?from=set-name" />;
  }

  if (hasSubmitted) {
    if (location.search) {
      const { from } = queryString.parse(location.search);
      return <Redirect to={from as string} />;
    } else {
      return <Redirect to="/home" />;
    }
  }

  return (
    <GenericForm<any>
      title="Set Name"
      fields={[
        {
          type: "text",
          name: "firstName",
          label: "First Name",
          formatRegex: FormatRegex.English
        },
        {
          type: "text",
          name: "lastName",
          label: "Last Name",
          formatRegex: FormatRegex.English
        }
      ]}
      disabled={isLoading}
      submitDisplayText={"Submit"}
      onSubmit={({ firstName, lastName }) => {
        updateDisplayName(firstName + " " + lastName, () => {
          setHasSubmitted(true);
        });
      }}
    />
  );
};

export default withRouter(connect(
  (state: ApplicationState) => state.user,
  actionCreators
)(SetNamePage as any) as any);

// function usePrevious(value: any) {
//   // The ref object is a generic container whose current property is mutable ...
//   // ... and can hold any value, similar to an instance property on a class
//   const ref = React.useRef();

//   // Store current value in ref
//   React.useEffect(() => {
//     ref.current = value;
//   }, [value]); // Only re-run if value changes

//   // Return previous value (happens before update in useEffect above)
//   return ref.current;
// }
