import React from "react";
import { withRouter } from "react-router";

import * as Common from "./../stores/commonStore";
import { Typography, Container } from "@material-ui/core";
import { ApplicationState } from "../stores";
import { connect } from "react-redux";
import { UserState } from "../stores/userStore";
import MyLink from "../components/MyLink";

type Props = typeof Common.actionCreators & UserState;

const UnregisterdUserPage: React.FC<Props> = ({ isLoading }) => {
  if (isLoading) return null;
  return (
    <Container>
      <Typography>You are not allow to use this system (Waa Waa).</Typography>
      <Typography>Contact admin to register you.</Typography>
      <Typography>Who's the admin you might ask, well.</Typography>
      <Typography>
        If you don't know the mighty admin, you probably should not be allowed
        here
      </Typography>
      <Typography>
        Either request access or try using a registered number.
      </Typography>

      <MyLink to="login">Login again</MyLink>
    </Container>
  );
};

export default withRouter(Common.connectCommonStore(
  connect(
    (state: ApplicationState) => state.user,
    {}
  )(UnregisterdUserPage)
) as any);
