import * as React from "react";
import {
  AdminState,
  actionCreators,
  connectCommonStore
} from "../stores/adminStore";
import { connect } from "react-redux";
import { ApplicationState } from "../stores";
import { withRouter, RouteComponentProps } from "react-router";
import {
  Container,
  Zoom,
  Fab,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Icon,
  withStyles,
  Theme,
  createStyles,
  Button
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as Common from "../stores/commonStore";

type AdminPageProps = AdminState &
  typeof actionCreators &
  typeof Common.actionCreators &
  Readonly<RouteComponentProps<any>>;

const AdminPage: React.FC<AdminPageProps> = ({
  users,
  loadRegisteredUsersInfo,
  location,
  ...props
}: AdminPageProps) => {
  React.useEffect(() => {
    loadRegisteredUsersInfo();
  }, [location, loadRegisteredUsersInfo]);

  return (
    <Container>
      {users.length === 0 ? (
        "No users registered"
      ) : (
        <>
          <Typography variant="h2">Users</Typography>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography>
                    <span role="img" aria-label="doctor">
                      👨‍🔬
                    </span>
                    Name
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <span role="img" aria-label="phone">
                      📞
                    </span>
                    Phone Number
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <span role="img" aria-label="evil_face">
                      😈
                    </span>
                    Delete
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((l, i) => (
                <StyledTableRow key={i}>
                  <TableCell align="left">
                    <Typography>{l.displayName}</Typography>
                  </TableCell>

                  <TableCell align="right">{l.phoneNumber}</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() =>
                        props.showDialog({
                          type: "YesNo",
                          title: "Remove User Access Confirmation",
                          message: `Are you sure you want to remove access for '${l.phoneNumber +
                            (l.displayName && " - " + l.displayName)}'?`,
                          onYes: () => props.unregisterUser(l.phoneNumber)
                        })
                      }
                    >
                      <Icon style={{ margin: "auto" }}>delete</Icon>
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

      <Zoom
        in={true}
        timeout={500}
        style={{
          transitionDelay: `0ms`,
          position: "fixed",
          right: "40px",
          bottom: "40px"
        }}
        unmountOnExit
      >
        <Fab
          aria-label="hello"
          color="primary"
          onClick={() =>
            props.showDialog({
              type: "Input",
              title: "Allow User Access",
              message: "Enter phone number, etc: 12345678",
              onYes: null,
              onSubmit: props.registerUser
            })
          }
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Container>
  );
};

export default withRouter(connectCommonStore(
  connect(
    (state: ApplicationState) => state.admin,
    { ...actionCreators, ...Common.actionCreators }
  )(AdminPage as any)
) as any);

//duplicate in Species.tsx
const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.default
      }
    }
  })
)(TableRow);
