import * as User from "./userStore";
import * as List from "./animalListStore";
import * as Common from "./commonStore";
import * as Admin from "./adminStore";

// The top-level state object
export interface ApplicationState {
  user: User.UserState;
  list: List.ListState;
  common: Common.CommonState;
  admin: Admin.AdminState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationStatimport { CartState } from './userStore.1';

export const reducers = {
  user: User.reducer,
  list: List.reducer,
  common: Common.reducer,
  admin: Admin.reducer
};

export const actions = {
  user: User.actionCreators,
  list: List.actionCreators,
  common: Common.actionCreators,
  admin: Admin.actionCreators
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
