import * as firebase from "firebase/app";
import firebaseConfig from "./firebaseConfig.json";

require("firebase/firestore");
require("firebase/auth");

const config = {
  apiKey: "",
  authDomain: "",
  databaseURL: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",

  ...firebaseConfig
};

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.auth().languageCode = "en";

//To avoid weird error in firebase. firebase team will fix next update .

const firestore = firebase.firestore();

// Need to add this to forgo deprecated warnings
// db.settings({
//   timestampsInSnapshots: true
// });

export default firestore;
